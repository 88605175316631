import PropTypes from "prop-types";
import React from "react";
import "typeface-montserrat";

// import Header from "./header";
import Footer from "./footer";

function Layout({ children }) {
  return (
    <div className="relative bg-white overflow-hidden">
      {children}
      <Footer />
    </div>
  );
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Layout;
